<template>
    <div class="Coach-List">
        <head-img desc="教练团队" :imgUrl="horseBgUrl"></head-img>
        <my-menu ref="menu" :titleList="titleList" :menuList="menuList" @changeTab="changeTab"></my-menu>
       
        <div class="horse-content">
            <div class="content">
                <div class="news-list">
                    <div class="item" v-for="(item, i) in coachList" :key="i" @click="goDetail(item.id)">
                        <div class="img">
                            <el-image class="img-news" :src="getImg(item.workPhotoUrl)" fit="cover"></el-image>
                        </div>
                        <div class="title">
                            {{item.coachName}}
                            <el-tag size="mini" type="info">{{item.coachLevelName || '***'}}</el-tag>
                        </div>
                        <div class="text">
                            <p>隶属马场： {{item.corpName}}</p>
                        </div>
                        <div class="btn">
                            <span>查看详情</span>
                            <span><i class="el-icon-right"></i></span>
                        </div>
                    </div>
                </div>

                <el-pagination
                    background
                    layout="prev, pager, next"
                    :total="total"
                    @current-change="currentPage"
                >
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            horseBgUrl: require('@/assets/img/home/lunbo4.jpg'),
            menuList: [
                {name: '首页', path: '/'},
                {name: '教练团队'},
            ],
            titleList: [ ],
            coachList: [],
            page: {
                current: 1,
                size: 10,
                orders: [{
                    column: "makeTime",
                    asc: false
                }]
            },
            total: 0,
            model: {}

        }
    },
    methods:{
        goDetail(id) {
            this.$router.push({path: '/coach/detail', query: {id}})
        },
        changeTab(data) {
            console.log(data)
            this.$refs.menu.setValue(true);
        },
        currentPage(page) {
            console.log(page)
            this.page.current = page
            this.getCoach()
        },
        getCoach() {
            this.$store.commit('loading', 'Coach-List .horse-content')
            this.$api.post('base/coach/coach-listForHomePage', {page: this.page, model: this.model}).then(res=>{
                console.log(res)
                this.total = res.data.total
                this.coachList = res.data.records
            })
            .finally(()=>{
                this.$store.commit('loaded')
            })
        }
    },
    mounted() {
        this.getCoach()
    }
}
</script>

<style lang="scss">
    @import "~@/assets/css/var";
    .Coach-List{
        .horse-content{
            display: flex;
            justify-content: center;
            align-items: center;
            .content{
                width: 1200px;
                margin-top: 50px;
                .news-list{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    .item{
                        width: 315px;
                        margin-left: 30px;
                        margin-bottom: 20px;
                        border: 1px solid #ededed;
                        &:hover{
                            cursor: pointer;
                        }
                        .img{
                            height: 230px;
                            &-news{
                                height: 230px;
                                width: 100%;
                            }
                        }
                        .title{
                            padding: 10px 20px;
                            font-size: 16px;
                            color: #333;
                        }
                        .text {
                            margin: 0 20px;
                            padding-bottom: 10px;
                            border-bottom: 1px solid #ededed;
                            p{
                                // width: 800px;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 2;
                                overflow: hidden;
                            }
                        }
                        .btn{
                            padding: 10px 20px;
                            display: flex;
                            justify-content: space-between;
                            i{
                                font-size: 20px;
                                &:hover{
                                    color: $main;
                                }
                            }
                        }
                        
                    }
                    .bgcolor{
                        img{
                            transition: all 0.3s;
                            transform: scale(1.1);
                            cursor: pointer;
                        }
                    }
                }
                .el-pagination{
                    margin: 20px;
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }

</style>